type FetchDashboardParams = {
    walletAddress: string;
    ref: string;
  };
  
  type InsertSwapParams = {
    inputCurrency: string;
    outputCurrency: string;
    swapAmountUSD: string;
    walletAddress: string;
    txhash: string;
    ref: string;
    network: string;
  };
  
  export async function fetchDashboard(params: FetchDashboardParams) {
    const { ref, walletAddress } = params;
    try {
      const response = await fetch("https://x-rewardbackendtest.julswap.com/julx-rewards-backend/dashboard", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          walletAddress,
          ref,
        }),
      });
  
      const data = await response.json();
      return { data, error: null };
    } catch (error) {
      return { data: null, error };
    }
  }
  
  export async function insertSwap(params: InsertSwapParams) {
    const {
      inputCurrency,
      outputCurrency,
      ref,
      swapAmountUSD,
      txhash,
      walletAddress,
      network,
    } = params;
    try {
      const response = await fetch("https://x-rewardbackendtest.julswap.com/julx-rewards-backend/insertSwap", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          inputCurrency,
          outputCurrency,
          swapAmountUSD,
          walletAddress,
          txhash,
          ref,
          network
        }),
      });
  
      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error("Error:", error);
    }
  }
  
  /*
  we have to integrate 2 custom APIs 
  1. when user connects wallet we need to make a API call which load his dashboard (total points, referral link, referral rewards)
  2. when user completes a transaction, we need to make a call to API which will insert swap on backend
  curl --location 'https://x-rewardbackendtest.julswap.com/julx-rewards-backend/dashboard' \
  --header 'Content-Type: application/x-www-form-urlencoded' \
  --data-urlencode 'walletAddress=1234' \
  --data-urlencode 'ref=621909eed5'
  curl --location 'https://x-rewardbackendtest.julswap.com/julx-rewards-backend/insertSwap' \
  --header 'Content-Type: application/x-www-form-urlencoded' \
  --data-urlencode 'inputCurrency=0xf5D8015D625be6F59b8073C8189BD51bA28792e1' \
  --data-urlencode 'outputCurrency=0xdac17f958d2ee523a2206206994597c13d831ec7' \
  --data-urlencode 'swapAmountUSD=1000' \
  --data-urlencode 'walletAddress=0x025E77cC7337f44946B61c4c2dCf83247cC9A8F1' \
  --data-urlencode 'txhash=12311' \
  --data-urlencode 'ref=0ceb5b763c'
  */