import { InterfacePageName } from '@uniswap/analytics-events'
import { ChainId, Currency } from '@uniswap/sdk-core'
import { Trace } from 'analytics'
import { NetworkAlert } from 'components/NetworkAlert/NetworkAlert'
import { SwitchLocaleLink } from 'components/SwitchLocaleLink'
import SwapHeader from 'components/swap/SwapHeader'
import { SwapTab } from 'components/swap/constants'
import { PageWrapper, SwapWrapper } from 'components/swap/styled'
import { useSupportedChainId } from 'constants/chains'
import { useScreenSize } from 'hooks/screenSize'
import { SendForm } from 'pages/Swap/Send/SendForm'
import { ReactNode, useEffect } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import { InterfaceTrade, TradeState } from 'state/routing/types'
import { isPreviewTrade } from 'state/routing/utils'
import { SwapAndLimitContextProvider, SwapContextProvider } from 'state/swap/SwapContext'
import { useInitialCurrencyState } from 'state/swap/hooks'
import { CurrencyState, SwapAndLimitContext } from 'state/swap/types'
import { useChainId } from 'wagmi'
import { useIsDarkMode } from '../../theme/components/ThemeToggle'
import { LimitFormWrapper } from './Limit/LimitForm'
import { SwapForm } from './SwapForm'
import Tooltip, { TooltipSize } from 'components/Tooltip'
import useCopyClipboard from 'hooks/useCopyClipboard'
import styled, { useTheme } from 'styled-components'
import { ClickableStyle, EllipsisStyle, ExternalLink, ThemedText } from 'theme/components'
import { ChevronRight, Copy } from 'react-feather'
import Row from 'components/Row'
import { Trans, t } from 'i18n'
import { useCallback, useState } from 'react'
import { Input } from 'components/NumericalInput'
import { useWeb3React } from '@web3-react/core'

import { Slide } from 'react-slideshow-image'
import 'react-slideshow-image/dist/styles.css'
import './style.css'

import '../../utils/api'
import { fetchDashboard, insertSwap } from '../../utils/api'

const spanStyle = {
  padding: '20px',
  background: '#efefef',
  color: '#000000'
}

const divStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundSize: 'cover',
  height: '400px'
}
const slideImages = [
  {
    url: './images/JulSwapX Ad Banners/1.png',
  },
  {
    url: './images/JulSwapX Ad Banners/2.png',
  },
  {
    url: './images/JulSwapX Ad Banners/3.png',
  },
  {
    url: './images/JulSwapX Ad Banners/4.png',
  },
  {
    url: './images/JulSwapX Ad Banners/5.png',
  },
  {
    url: './images/JulSwapX Ad Banners/6.png',
  },
  {
    url: './images/JulSwapX Ad Banners/7.png',
  },
  {
    url: './images/JulSwapX Ad Banners/8.png',
  },
];

const CopyAddress = styled.input`
  gap: 8px;
  padding: 8px 12px;
  border-radius: 8px;
  background-color: black;
  color: rgb(160, 160, 160);
  font-size: 16px;
  font-weight: 100;
  line-height: 16px;
  width: max-content;
  flex-shrink: 0;
  width: 100%;
  flex: 1;
  height: 50px;
  border: 1px solid #4144B5;
  // ${ClickableStyle}
`
const StyledCopyIcon = styled(Copy)`
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.neutral2};
  flex-shrink: 0;
  background-color: black;
`

export function getIsReviewableQuote(
  trade: InterfaceTrade | undefined,
  tradeState: TradeState,
  swapInputError?: ReactNode
): boolean {
  if (swapInputError) return false
  // if the current quote is a preview quote, allow the user to progress to the Swap review screen
  if (isPreviewTrade(trade)) return true

  return Boolean(trade && tradeState === TradeState.VALID)
}

export default function SwapPage({ className }: { className?: string }) {
  const location = useLocation()

  const { initialInputCurrency, initialOutputCurrency, chainId } = useInitialCurrencyState()
  const shouldDisableTokenInputs = useSupportedChainId(useChainId()) === undefined

  return (
    <Trace page={InterfacePageName.SWAP_PAGE} shouldLogImpression>
      <PageWrapper>
        <Swap
          className={className}
          chainId={chainId}
          disableTokenInputs={shouldDisableTokenInputs}
          initialInputCurrency={initialInputCurrency}
          initialOutputCurrency={initialOutputCurrency}
          syncTabToUrl={true}
        />
        {location.pathname !== '/limit' && <NetworkAlert />}
      </PageWrapper>
      {location.pathname === '/swap' && <SwitchLocaleLink />}
    </Trace>
  )
}

/**
 * The swap component displays the swap interface, manages state for the swap, and triggers onchain swaps.
 *
 * In most cases, chainId should refer to the connected chain, i.e. `useWeb3React().chainId`.
 * However if this component is being used in a context that displays information from a different, unconnected
 * chain (e.g. the TDP), then chainId should refer to the unconnected chain.
 */
export function Swap({
  className,
  initialInputCurrency,
  initialOutputCurrency,
  chainId,
  onCurrencyChange,
  disableTokenInputs = false,
  compact = false,
  syncTabToUrl,
}: {
  className?: string
  chainId?: ChainId
  onCurrencyChange?: (selected: CurrencyState) => void
  disableTokenInputs?: boolean
  initialInputCurrency?: Currency
  initialOutputCurrency?: Currency
  compact?: boolean
  syncTabToUrl: boolean
}) {
  const isDark = useIsDarkMode()
  const screenSize = useScreenSize()

  const { account } = useWeb3React()


  const [isCopied, setCopied] = useCopyClipboard()

  const [tradeRewards, setTradeRewards] = useState(0)
  const [referralRewards, setReferralRewards] = useState(0)
  const [referCode, setReferCode] = useState('')
  const [referUrl, setReferUrl] = useState('')

  const copy = useCallback(() => {
    setCopied(referUrl)
  }, [referUrl, setCopied])

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if ( account == null ) {
      setTradeRewards(0)
      setReferralRewards(0)
      setReferUrl('')
      setReferCode('')
      return;
    }

    const searchPa = new URL(window.location.href).searchParams;
    const refValue = searchPa.get('ref') ?? '';
    // console.log('Ref: ', refValue);

    // console.log('complete URL: ', window.location.href)

    // const refParam = searchParams.get("ref") ?? '';
    // console.log('Ref Param:', refParam);

    fetchDashboard({ walletAddress: account ?? '', ref: refValue } ).then(({data, error}) => {
      // console.log(account)
      // console.log(data, error)
      if (error) { 
        console.error('Error fetching dashboard:', error);
        return; 
      }


      setTradeRewards(data.data.availablePoints)
      setReferralRewards(data.data.totalReward)
      setReferUrl(data.data.referralCode)
      setReferCode(refValue)
    })
      
  }, [account, searchParams])


  return (
    <SwapAndLimitContextProvider
      chainId={chainId}
      initialInputCurrency={initialInputCurrency}
      initialOutputCurrency={initialOutputCurrency}
    >
      {/* TODO: Move SwapContextProvider inside Swap tab ONLY after SwapHeader removes references to trade / autoSlippage */}
      <SwapAndLimitContext.Consumer>
        {({ currentTab }) => (
          <SwapContextProvider>
            <SwapWrapper isDark={isDark} className={className} id="swap-page">
              {/* <img src='./images/image5.png' alt='' width='100%' style={{ marginBottom: '20px' }}></img> */}
              <div className="slide-container" style={{ marginTop: '0px' }}>
                <Slide>
                {slideImages.map((slideImage, index)=> (
                    <div key={index}>
                        <img src={slideImage.url} alt='' width='100%' style={{ marginBottom: '20px', borderRadius: '10px' }}></img>
                    </div>
                  ))} 
                </Slide>
              </div>

              <SwapHeader compact={compact || !screenSize.sm} syncTabToUrl={syncTabToUrl} />
              
              {currentTab === SwapTab.Swap && (
                <SwapForm referCode={referCode} onCurrencyChange={onCurrencyChange} disableTokenInputs={disableTokenInputs} compact={compact || !screenSize.sm} />
              )}
              {currentTab === SwapTab.Limit && <LimitFormWrapper onCurrencyChange={onCurrencyChange} />}
              {currentTab === SwapTab.Send && (
                <SendForm disableTokenInputs={disableTokenInputs} onCurrencyChange={onCurrencyChange} />
              )}

              {
                account && (<div style={{ display: 'flex', marginTop: '20px', justifyContent: 'space-between', gap: '8px', position: 'relative' }}>
                  <div style={{ textAlign: 'center', marginTop: '5px' }}>
                    <img src='./images/refer.png'></img><br></br>
                    <Trans>Refer:</Trans>
                  </div>
                  <CopyAddress value={referUrl} />
                  <Tooltip placement="bottom" size={TooltipSize.Max} show={isCopied} text={t`Copied`} style={{ position: 'absolute', right: '-3px', top: '23%', backgroundColor: 'black', paddingLeft: '7px', transform: 'translateX(-50%)' }}>
                    <StyledCopyIcon onClick={copy} />
                  </Tooltip>
                </div>)
              }
              {
                account && (<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
                  <div style={{ width: '48%', height: '120px', backgroundColor: 'rgba(65, 68, 181, .2)', border: '1px solid rgba(65, 68, 181, .3)', borderRadius: '8px' }}>
                    <p style={{ fontSize: '36px', textShadow: '2px 2px rgba(0, 0, 0, .25)', color: 'white', textAlign: 'center', marginTop: '20px' }}>{tradeRewards}</p>
                    <p style={{ fontSize: '20px', textShadow: '2px 2px rgba(0, 0, 0, .25)', color: 'white', textAlign: 'center', marginTop: '-25px' }}>Trade Rewards</p>
                  </div>
                  <div style={{ width: '48%', height: '120px', backgroundColor: 'rgba(65, 68, 181, .2)', border: '1px solid rgba(65, 68, 181, .3)', borderRadius: '8px' }}>
                    <p style={{ fontSize: '36px', textShadow: '2px 2px rgba(0, 0, 0, .25)', color: 'white', textAlign: 'center', marginTop: '20px' }}>{referralRewards}</p>
                    <p style={{ fontSize: '20px', textShadow: '2px 2px rgba(0, 0, 0, .25)', color: 'white', textAlign: 'center', marginTop: '-25px' }}>Referral Rewards</p>
                  </div>
                </div>)
              }
            </SwapWrapper>
          </SwapContextProvider>
        )}
      </SwapAndLimitContext.Consumer>
    </SwapAndLimitContextProvider>
  )
}
